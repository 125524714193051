import axios, { AxiosInstance } from 'axios';
import { groupBy, flatten } from 'lodash';

const KNOWLEDGE_BASE_COLLECTION = '6458e9d43225e321f2948a98';

type ICategoryResponse = {
  categories: {
    count: number;
    page: number;
    pages: number;
    items: ICategory[];
  };
};

export type ICategory = {
  articleCount: number;
  collectionId: string;
  createdAt: Date;
  createdBy: number;
  defaultSort: string;
  description: string | null;
  id: string;
  categoryId: string; // Added during gatsby build
  name: string;
  number: number;
  order: number;
  publicUrl: string;
  publishedArticleCount: number;
  slug: string;
  updatedAt: Date;
  updatedBy: number;
  visibility: string;
};

type IArticleResponse = {
  articles: {
    count: number;
    page: number;
    pages: number;
    items: IArticle[];
  };
};

type IArticle = {
  collectionId: string;
  createdAt: string;
  createdBy: number;
  hasDraft: boolean;
  id: string;
  lastPublishedAt: string;
  name: string;
  number: number;
  popularity: number;
  publicUrl: string;
  slug: string;
  status: string;
  updatedAt: string;
  updatedBy: number;
  viewCount: number;
};

type IArticleDetailResponse = {
  article: IArticleDetail;
};

export type IArticleDetail = {
  categories: ICategory['id'][];
  collectionId: string;
  createdAt: string;
  createdBy: number;
  hasDraft: boolean;
  id: string;
  keywords: any[];
  articleId: string;
  lastPublishedAt: string;
  name: string;
  number: number;
  popularity: number;
  publicUrl: string;
  related: string[];
  slug: string;
  status: string;
  text: string;
  updatedAt: string;
  updatedBy: number;
  viewCount: number;
};

export const createClient = (apiKey: string) => {
  return axios.create({
    baseURL: 'https://docsapi.helpscout.net/v1/',
    auth: {
      username: apiKey,
      password: 'X'
    }
  });
};

export const getCategories = (client: AxiosInstance) => {
  return client
    .get<ICategoryResponse>(
      `/collections/${KNOWLEDGE_BASE_COLLECTION}/categories`
    )
    .then((resp) => resp.data.categories.items);
};

export const getArticleList = async (client: AxiosInstance) => {
  const page1 = await client.get<IArticleResponse>(
    `/collections/${KNOWLEDGE_BASE_COLLECTION}/articles?pageSize=100&page=1`
  );
  return flatten([page1].map((x) => x.data.articles.items));
};

export const getArticleDetail = (client: AxiosInstance, id: string) => {
  return client.get<IArticleDetailResponse>(`/articles/${id}`);
};

export const getArticlesWithContent = async (client: AxiosInstance) => {
  const items = await getArticleList(client);
  const articleDetails = items.map((item) => getArticleDetail(client, item.id));

  return Promise.all(articleDetails).then((resps) => {
    return resps.map((r) => r.data.article);
  });
};

export const groupArticlesInCategory = (articles: IArticleDetail[]) => {
  const groups = groupBy(articles, (a) => {
    const keywords = a.keywords;
    if (!keywords || !keywords.length) {
      return 'articles';
    }
    return keywords[0].toLowerCase();
  });

  const groupNames = Object.keys(groups);
  const referenceOrder = [
    'featured',
    'overview',
    // getting started
    'the basics',
    'setting up your account',
    // affiliate dashboard
    'transaction reports',
    'other reports',
    'integration settings',
    'revenue attribution',
    // integrations
    'integrations',
    // link management
    'import your links',
    'working with links',
    'cloaked links',
    'amazon link checker',
    'export links',
    // content analytics
    'content trends',
    'heatmaps',
    'segmentation',
    'revisions',
    // api
    'endpoints',
    // misc
    'frequently asked questions',
    'tutorials',
    'best practices',
    'troubleshooting',
    'migrating',
    'other'
  ];

  groupNames.sort((a, b) => {
    return referenceOrder.indexOf(a) > referenceOrder.indexOf(b) ? 1 : -1;
  });

  return { groupNames, groups };
};
