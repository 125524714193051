import React from 'react';
import { SEO } from '../components/SEO';
import styled from '../styled';
import {
  ICategory,
  IArticleDetail,
  groupArticlesInCategory
} from '../services/helpscout';
import { HelpCenter } from '../layouts/HelpCenter';
import { ArticleList } from '../components/ArticleList';
import { capitalize } from 'lodash-es';
import { Typography } from '@material-ui/core';

type IContext = {
  articles: IArticleDetail[];
  category: ICategory;
  categories: ICategory[];
};

const Content = ({
  category,
  articles
}: {
  category: ICategory;
  articles: IArticleDetail[];
}) => {
  const { groups, groupNames } = groupArticlesInCategory(articles);

  return (
    <main>
      <Typography
        variant="h4"
        component="h1"
        style={{ fontWeight: 'bold', marginTop: '18px', marginBottom: '6px' }}
      >
        {category.name}
      </Typography>
      <Typography variant="body1" color="textSecondary" component="p">
        {category.description}
      </Typography>
      {groupNames.map((name) => (
        <React.Fragment key={name}>
          <h2>{capitalize(name)}</h2>
          <ArticleList articles={groups[name]} />
        </React.Fragment>
      ))}
    </main>
  );
};

export default function Template({ pageContext }: { pageContext: IContext }) {
  const { category } = pageContext;

  return (
    <>
      <SEO
        title={category.name}
        pathname={`/docs/${category.slug}/`}
        description="Support, tutorials, and best practices for affiliate publishers using Affilimate to grow their revenue."
        image="/images/help-center.png"
      />
      <HelpCenter
        content={
          <Content category={category} articles={pageContext.articles} />
        }
        currentCategory={pageContext.category}
        articles={pageContext.articles}
        categories={pageContext.categories}
      />
    </>
  );
}
