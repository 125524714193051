import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import styled from '../styled';
import { File, Star } from 'react-feather';
import { IArticleDetail } from '../services/helpscout';
import { sortBy } from 'lodash-es';

const Articles = styled('ul')`
  font-size: 16px;
  line-height: 30px;
  list-style: none;
  padding: 0;

  li {
    line-height: 22px;
    margin-bottom: 8px;

    & > svg {
      position: relative;
      top: 2px;
      margin-right: 12px;
      stroke: ${(p) => p.theme.palette.grey[500]};
    }
  }

  a {
    display: inline;
    color: ${(p) => p.theme.palette.primary.main};
    border-bottom: 1px solid;
  }
`;

const FeaturedArticle = styled('span')`
  background-color: #fff1b8;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  display: inline-block;
  padding: 1px 4px;
  margin-left: 2px;
  position: relative;
  top: 3px;

  svg {
    stroke: #faad14 !important;
  }
`;

export const ArticleList = ({
  articles
}: {
  articles: Pick<IArticleDetail, 'id' | 'slug' | 'name' | 'keywords'>[];
}) => {
  const sortedArticles = useMemo(() => {
    return sortBy(articles, (a) => a.name.toLowerCase());
  }, [articles]);
  return (
    <Articles>
      {sortedArticles.map((a) => (
        <li key={a.id}>
          <File size={16} /> <Link to={`/qs/${a.slug}/`}>{a.name}</Link>{' '}
          {(a.keywords || []).includes('featured') && (
            <FeaturedArticle>
              <Star size={14} />
            </FeaturedArticle>
          )}
        </li>
      ))}
    </Articles>
  );
};
